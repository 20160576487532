import * as React from 'react';
import Tokens from './Tokens';

const Dashboard = () => {
  return (
    <div className='container py-4'>
      <div className='row'>
        <div className='col-12 col-md-10 mx-auto'>
          <Tokens />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
