import * as React from 'react';
import { refreshAccount, transactionServices } from '@elrondnetwork/dapp-core';
import { Button, Table } from 'react-bootstrap';
import { balanceStringToNumber } from '../helpers/numberHelper';
import {
  buildWithdrawESDTSFTTokens,
  buildWithdrawESDTTokens
} from '../helpers/transactionBuilder';

const TokenTable = (tokenProps: any) => {
  const tokens = tokenProps.tokens;

  const { sendTransactions } = transactionServices;
  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
      string | null
    >(null);

  const sendWithdrawAllTokensTx = async (allTokens: any[]) => {
    const withdrawTx = tokenProps.isSfts
      ? buildWithdrawESDTSFTTokens(tokenProps.contract, allTokens)
      : buildWithdrawESDTTokens(tokenProps.contract, allTokens);
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: withdrawTx,
      transactionsDisplayInfo: {
        processingMessage: 'Withdraw Tokens...',
        errorMessage: 'An error has occurred during withdraw',
        successMessage: 'Withdraw transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Token</th>
          <th>Identifier</th>
          <th>Balance</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {tokens.map((tokenData: any, tokenIndex: number) => {
          return (
            <tr key={tokenIndex}>
              <td>{tokenData.name}</td>
              <td>{tokenData.identifier}</td>
              <td>{balanceStringToNumber(tokenData.balance)}</td>
              <td>
                <Button
                  className='claim-btn'
                  onClick={() => sendWithdrawAllTokensTx([tokenData])}
                >
                  Claim
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TokenTable;
