import { Buffer } from 'buffer';
import BigNumber from 'bignumber.js';

export const balanceStringToNumber = (balanceString: string) => {
  if (balanceString && balanceString !== '0') {
    return new BigNumber(balanceString).div(10 ** 18).toFixed(5);
  }
  return '0';
};

export const nbToHex = (nb: number) => {
  const hexStringNumber = nb.toString(16);
  return hexStringNumber.length % 2 === 0
    ? hexStringNumber
    : `0${hexStringNumber}`;
};

export const bigNumberToHex = (strAmount: string) => {
  const bn = new BigNumber(strAmount);
  const hex = bn.toString(16);
  return hex.length % 2 === 0 ? hex : `0${hex}`;
};

export const strToHex = (str: string) => {
  return Buffer.from(new TextEncoder().encode(str)).toString('hex');
};
