export const environment = 'mainnet';

export const dAppName = 'Admin';

export const authorizations = [
  {
    name: 'ElrondApes',
    contract: 'erd1qqqqqqqqqqqqqpgq7gma4udjeh3mwuq4lmk7wccgh0tenxu6yv5qc2csfy',
    operators: [
      'erd1ymcygz4qhf9p29a8nv7clwer4ee9fzrfshjvg4a3jchwv0vlyv5qhxgle9'
    ]
  },
  {
    name: 'Aerovek Aviators',
    contract: 'erd1qqqqqqqqqqqqqpgq9vfqpf708y77n46flp8vqhvaw2sv7sscq5cq5n7pjy',
    operators: [
      'erd1hc8499l9czmnqp2lsy95g2jyrlfg4kgyk4zgyh54qjyvfqcmzcaq3su730',
      'erd18y83yg4p3qn30lkxzuac3ycch2pxd3saxtxqv70wz3r50smgq5cqvpfd6g'
    ]
  }
];
