import { bigNumberToHex, nbToHex, strToHex } from './numberHelper';

export const buildWithdrawEgldTx = (
  contractAddress: string,
  strAmount: string
) => {
  const args = ['withdrawEgld', bigNumberToHex(strAmount)];

  return {
    value: '0',
    receiver: contractAddress,
    data: args.join('@')
  };
};

export const buildWithdrawESDTTokens = (
  contractAddress: string,
  tokens: any
) => {
  const args = ['withdrawTokens'];
  for (const token of tokens) {
    args.push(strToHex(token.identifier));
    args.push('');
  }

  return {
    value: '0',
    receiver: contractAddress,
    data: args.join('@')
  };
};

export const buildWithdrawESDTSFTTokens = (
  contractAddress: string,
  tokens: any
) => {
  const args = ['withdrawTokens'];
  for (const token of tokens) {
    args.push(strToHex(token.collection));
    args.push(nbToHex(token.nonce));
  }

  return {
    value: '0',
    receiver: contractAddress,
    data: args.join('@')
  };
};
