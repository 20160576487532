import * as React from 'react';
import { useState } from 'react';
import {
  getApiProvider,
  refreshAccount,
  transactionServices,
  useGetAccountInfo
} from '@elrondnetwork/dapp-core';
import { Button } from 'react-bootstrap';
import { authorizations } from '../../../config';
import { fetchAccountAndTokenInfo } from '../helpers/asyncRequests';
import { balanceStringToNumber } from '../helpers/numberHelper';
import {
  buildWithdrawEgldTx,
  buildWithdrawESDTSFTTokens,
  buildWithdrawESDTTokens
} from '../helpers/transactionBuilder';
import TokenTable from './TokenTable';
import './Tokens.scss';

const Tokens = () => {
  const { address } = useGetAccountInfo();
  const { sendTransactions } = transactionServices;
  const [showInfo, setShowInfo] = useState(false);
  const [authorizedContracts, setAuthorizedContracts] = useState([] as any);
  const [royaltiesInfo, setRoyaltiesInfo] = useState([] as any);
  const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<
      string | null
    >(null);
  React.useEffect(() => {
    let contracts = [] as any;
    let isAuthorized = false;
    if (address) {
      contracts = authorizations.filter((auth) =>
        auth.operators.includes(address)
      );
      isAuthorized = contracts.length > 0;
    }
    setAuthorizedContracts(contracts);
    setShowInfo(isAuthorized);
  }, [address]);

  // Fetch All infos
  React.useEffect(() => {
    setRoyaltiesInfo([]);
    if (address && authorizedContracts.length > 0) {
      const apiProvider = getApiProvider() as any;
      for (const authorizedContract of authorizedContracts) {
        fetchAccountAndTokenInfo(apiProvider.url, authorizedContract.contract)
          .then((data: any) => {
            const projectRoyaltiesInfo = data;
            projectRoyaltiesInfo.name = authorizedContract.name;
            projectRoyaltiesInfo.operators = authorizedContract.operators;
            console.log(projectRoyaltiesInfo);
            setRoyaltiesInfo((royalties: any) => [
              ...royalties,
              projectRoyaltiesInfo
            ]);
          })
          .catch((err) => console.error(err));
      }
    }
  }, [address, showInfo]);

  const sendWithdrawEgldTx = async (contract: string, balance: string) => {
    const withdrawTx = buildWithdrawEgldTx(contract, balance);
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: withdrawTx,
      transactionsDisplayInfo: {
        processingMessage: 'Withdraw EGLDs...',
        errorMessage: 'An error has occurred during withdraw',
        successMessage: 'Withdraw transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  const sendWithdrawAllTokensTx = async (
    contract: string,
    allTokens: any[],
    isSfts: boolean
  ) => {
    const withdrawTx = isSfts
      ? buildWithdrawESDTSFTTokens(contract, allTokens)
      : buildWithdrawESDTTokens(contract, allTokens);
    await refreshAccount();

    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: withdrawTx,
      transactionsDisplayInfo: {
        processingMessage: 'Withdraw Tokens...',
        errorMessage: 'An error has occurred during withdraw',
        successMessage: 'Withdraw transaction successful'
      },
      redirectAfterSign: false
    });
    if (sessionId != null) {
      setTransactionSessionId(sessionId);
    }
  };

  return (
    <div className='token-royalties-container'>
      {royaltiesInfo.length === 0 && <div>Nothing to show</div>}
      {royaltiesInfo.length > 0 &&
        royaltiesInfo.map((item: any, index: number) => {
          return (
            <div key={index}>
              <h3 className='h3-title'>{item.name}</h3>
              <div className='contract-address'>
                Contract: {item.accountInfo.address}
              </div>

              <div className='title-container'>
                <h3 className='h3-title'>EGLD</h3>
                {item.accountInfo && item.accountInfo.balance !== '0' && (
                  <Button
                    className='claim-btn'
                    onClick={() =>
                      sendWithdrawEgldTx(
                        item.accountInfo.address,
                        item.accountInfo.balance
                      )
                    }
                  >
                    Claim {balanceStringToNumber(item.accountInfo.balance)} EGLD
                  </Button>
                )}
              </div>

              {item.tokens && item.tokens.length > 0 && (
                <>
                  <div className='title-container'>
                    <h3 className='h3-title'>ESDT Tokens</h3>
                    {item.tokens.length > 1 && (
                      <Button
                        className='claim-btn'
                        onClick={() =>
                          sendWithdrawAllTokensTx(
                            item.accountInfo.address,
                            item.tokens,
                            false
                          )
                        }
                      >
                        Claim Tokens
                      </Button>
                    )}
                  </div>
                  <TokenTable
                    tokens={item.tokens}
                    isSfts={false}
                    contract={item.accountInfo.address}
                  />
                </>
              )}

              {item.metaTokens && item.metaTokens.length > 0 && (
                <>
                  <div className='title-container'>
                    <h3 className='h3-title'>ESDT SFT Tokens</h3>
                    {item.metaTokens.length > 1 && (
                      <Button
                        className='claim-btn'
                        onClick={() =>
                          sendWithdrawAllTokensTx(
                            item.accountInfo.address,
                            item.metaTokens,
                            true
                          )
                        }
                      >
                        Claim Tokens
                      </Button>
                    )}
                  </div>
                  <TokenTable
                    tokens={item.metaTokens}
                    isSfts={true}
                    contract={item.accountInfo.address}
                  />
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Tokens;
