import axios from 'axios';

interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: 'must',
          size: 25
        },
        timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

export const getTransactions = fetchTransactions('/transactions');
export const getTransactionsCount = fetchTransactions('/transactions/count');

export const fetchAccountAndTokenInfo = async (
  apiUrl: string,
  account: string,
  timeout?: number
) => {
  const getAccountInfoUrl = `${apiUrl}/accounts/${account}`;
  const getAccountInfo = await axios.get(getAccountInfoUrl, {
    timeout: timeout ? timeout : 6000
  });

  const getTokenUrl = `${apiUrl}/accounts/${account}/tokens?from=0&size=500`;
  const getTokenResult = await axios.get(getTokenUrl, {
    timeout: timeout ? timeout : 6000
  });

  const getMetaTokenUrl = `${apiUrl}/accounts/${account}/nfts?type=MetaESDT&from=0&size=500&includeFlagged=true`;
  const getMetaTokenResult = await axios.get(getMetaTokenUrl, {
    timeout: timeout ? timeout : 6000
  });

  return {
    account: account,
    accountInfo: getAccountInfo.status === 200 ? getAccountInfo.data : null,
    tokens: getTokenResult.status === 200 ? getTokenResult.data : [],
    metaTokens: getMetaTokenResult.status === 200 ? getMetaTokenResult.data : []
  };
};
